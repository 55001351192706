import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/sh-icon.png';

const Wrapper: FC = ({ children }) => (
  <div>
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
      <div className="text-left md:text-center lg:text-left">{children}</div>
    </main>
  </div>
);

function Welcome() {
  return (
    <Wrapper>
      <h1 className="flex items-center md:justify-center lg:justify-start text-4xl tracking-tight font-extrabold text-gray-900 md:text-6xl sm:mx-auto md:mt-5 lg:mx-0">
        <img src={logo} alt="Sodium Halogen logo" style={{ width: '65px' }} />{' '}
        <span className="pl-2">Mini Game</span>
      </h1>

      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
        A game of repetition and memorization to ensure you-know-you-know the
        important parts.
      </p>
      <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
        <div className="rounded-md shadow">
          <Link
            to="/demo"
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
          >
            Start Demo 🏎
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}

export default Welcome;
