import { motion } from 'framer-motion';
import React from 'react';

const containerStyle = {
  width: '3rem',
  height: '3rem'
};

const circleStyle = {
  display: 'block',
  width: '3rem',
  height: '3rem',
  border: '0.5rem solid #e9e9e9',
  borderTop: '0.5rem solid #2563EB',
  borderRadius: '50%',
  top: 0,
  left: 0
};

const spinTransition = {
  loop: Infinity,
  ease: 'linear',
  duration: 0.5
};

const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen ">
      <div style={containerStyle}>
        <motion.span
          style={circleStyle}
          animate={{ rotate: 360 }}
          transition={spinTransition}
        />
      </div>
    </div>
  );
};

// <div className="flex flex-col items-center justify-center h-screen opacity-30">
//   <div className="p-4 md:w-1/2">
//     <div className="h-full overflow-hidden">
//       <div className="p-6">
//         <div className="flex items-end">
//           <h1 className="inline-block w-1/3 mb-4 h-6 animate-pulse bg-gray-500 mr-2">
//             &nbsp;
//           </h1>

//           <h1 className="inline-block w-1/6 mb-4 h-1 animate-pulse bg-gray-500 mr-1">
//             &nbsp;
//           </h1>
//           <h1 className="inline-block w-1/6 mb-4 h-1 animate-pulse bg-gray-500 mr-1">
//             &nbsp;
//           </h1>
//           <h1 className="inline-block w-1/6 mb-4 h-1 animate-pulse bg-gray-500 mr-1">
//             &nbsp;
//           </h1>
//           <h1 className="inline-block w-1/6 mb-4 h-1 animate-pulse bg-gray-500 mr-1">
//             &nbsp;
//           </h1>
//           <h1 className="inline-block w-1/6 mb-4 h-1 animate-pulse bg-gray-500 mr-2">
//             &nbsp;
//           </h1>

//           <h1 className="inline-block w-2/4 mb-4 h-6 animate-pulse bg-gray-500">
//             &nbsp;
//           </h1>
//         </div>
//         <h2 className=" animate-pulse h-4 mb-2">loading your game...</h2>
//         <div className="flex flex-wrap">
//           <span className="bg-indigo-300 h-20 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0 mr-2"></span>
//           <span className="bg-indigo-300 h-20 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0 mr-2"></span>
//           <span className="bg-indigo-300 h-20 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0 mr-2"></span>
//           <span className="bg-indigo-300 h-20 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0 mr-2"></span>
//           <span className="bg-indigo-300 h-20 animate-pulse mt-2 w-32 inline-flex items-center md:mb-2 lg:mb-0 mr-2"></span>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

export default Loading;
