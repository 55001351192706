import React, { useEffect, useState, useCallback } from 'react';
import { MiniGameType, FactType } from '../types';
import SpellWord from './SpellWord';
import { motion } from 'framer-motion';
import ChooseWord from './ChooseWord';
import ImageMatch from './ImageMatch';
import shuffleArray from '../utils/shuffleArray';
import { useParams } from 'react-router-dom';
import LeaderBoard from './LeaderBoard';
import { GameController } from '../api/GameController/GameController';
import { PHASES } from '../api/GameController/PHASES';

const Game = ({ gameController }: { gameController: GameController }) => {
  const facts = gameController.facts;
  const { jsonFileName } = useParams<{ jsonFileName: string }>();
  const [hasGameEnded, setHasGameEnded] = useState(false);
  const [playsCount, setPlaysCount] = useState<number>(
    Number(window.localStorage.getItem('PLAYS-' + jsonFileName))
  );
  const [isLoading, setisLoading] = useState(false);
  const [gameIndex, setGameIndex] = useState<number>(0);
  const [miniGame, setMiniGame] = useState<MiniGameType>(
    gameController.currentMiniGameType
  );

  useEffect(() => {
    if (gameIndex > facts.length - 1) {
      const playsVal =
        window.localStorage.getItem('PLAYS-' + jsonFileName) || 0;
      window.localStorage.setItem(
        'PLAYS-' + jsonFileName,
        (Number(playsVal) + 1).toString()
      );
      setPlaysCount(Number(playsVal) + 1);
    }
  }, [facts.length, gameIndex, jsonFileName]);

  const handleNextFact = useCallback(() => {
    setisLoading(true);
    setTimeout(() => {
      gameController.answerFact('correct answer');
      setGameIndex(gameController.currentFactIndex);

      if (gameController.phase !== PHASES.END) {
        setMiniGame(gameController.currentMiniGameType);
      } else if (gameController.phase === PHASES.END) {
        setHasGameEnded(true);
      }
      setisLoading(false);
    }, 300);
  }, [gameController]);

  const handlePlayAgain = () => {
    gameController.playAgain();
    setGameIndex(gameController.currentFactIndex);
    setMiniGame(gameController.currentMiniGameType);
    setHasGameEnded(false);
  };

  const miniGameUi = useCallback(() => {
    if (
      miniGame === 'spellWord' &&
      gameController.getCurrentFact().miniGames['spellWord']
    ) {
      return (
        <SpellWord
          fact={gameController.getCurrentFact()}
          handleNextGame={handleNextFact}
          makeMistake={() => gameController.makeMistake()}
        />
      );
    }

    if (
      miniGame === 'chooseWord' &&
      gameController.getCurrentFact().miniGames['chooseWord']
    ) {
      return (
        <ChooseWord
          fact={gameController.getCurrentFact()}
          makeMistake={() => gameController.makeMistake()}
          allWords={shuffleArray(
            facts
              .filter((fact: FactType) => !!fact.miniGames['chooseWord'])
              .reduce(
                (acc: string[], f: any) => [...acc, f.miniGames['chooseWord']],
                []
              )
              .flat()
          )
            .filter(
              (w: string) =>
                !gameController
                  .getCurrentFact()
                  .miniGames['chooseWord']?.includes(w)
            )
            .slice(0, 5)}
          handleNextGame={handleNextFact}
        />
      );
    }

    if (
      miniGame === 'imageMatch' &&
      gameController.getCurrentFact().miniGames['imageMatch'] !== undefined
    ) {
      return (
        <ImageMatch
          fact={gameController.getCurrentFact()}
          handleNextGame={handleNextFact}
          makeMistake={() => gameController.makeMistake()}
        />
      );
    }

    return <>no game found</>;
  }, [facts, gameController, handleNextFact, miniGame]);

  if (hasGameEnded) {
    return (
      <div className="text-center">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl capitalize text-center mb-10">
          You've done it, Alice!! 💪
        </h1>
        <LeaderBoard />
        <p className="mt-10 mb-3 text-base text-gray-500">
          You've played {playsCount || 1}{' '}
          {playsCount > 1 ? 'times.' : 'time. Keep going!'}
        </p>
        <p className="mt-10 mb-3 text-base text-gray-500">
          You made {gameController.mistakeCount} mistake{gameController.mistakeCount !== 1 && 's'}
        </p>
        <p className="mt-10 mb-3 text-base text-gray-500">
          You took {gameController.getSurpassedTime()} seconds
        </p>
        <motion.button
          onClick={handlePlayAgain}
          className="flex-1 text-center px-8 py-3 border border-transparent text-lg font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:p-4 md:m-4 md:p-10"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: -40 },
            visible: { opacity: 1, y: 0 }
          }}
          transition={{ duration: 0.3 }}
          whileTap={{ scale: 0.9 }}
        >
          Play Again?
        </motion.button>
      </div>
    );
  }

  if (isLoading) return null;

  return (
    <>
      <span
        className="absolute text-gray-100 botom-0 right-5 opacity-5 cursor-pointer"
        onClick={() => {
          handleNextFact();
        }}
      >
        😛
      </span>
      <p className="text-center mb-4">
        {gameIndex + 1} / {facts.length}
      </p>
      {miniGameUi()}
    </>
  );
};

export default Game;
