import { motion } from 'framer-motion';
import React, { useState } from 'react';
type answerState = 'UNANSWERED' | 'WRONG' | 'CORRECT';

const ImageButton = ({
  text,
  handleSelection,
  isCorrectChoice,
  makeMistake
}: {
  text: string;
  handleSelection: () => void;
  isCorrectChoice: boolean;
  makeMistake: () => void;
}) => {
  const [disabled, setDisabled] = useState(false);
  const [hasAnswered, setHasAnswered] = useState<answerState>('UNANSWERED');
  const handleClick = () => {
    if (isCorrectChoice) {
      setDisabled(true);
      handleSelection();
      setHasAnswered('CORRECT');
    } else {
      console.log('wrong!!!', text);
      makeMistake();
      setDisabled(true);
      setHasAnswered('WRONG');
    }
  };
  const clickDisabled = () => {};
  const animationMap = {
    UNANSWERED: 'visible',
    WRONG: 'wrong',
    CORRECT: 'correct'
  };
  return (
    <div className={'w-full bg-white'}>
      {disabled !== true}
      <motion.img
        onClick={disabled !== true ? handleClick : clickDisabled}
        src={text}
        className={`w-full h-full object-contain text-center px-4 md:px-8 py-3 border border-transparent text-lg font-medium rounded-md md:p-4 ${
          disabled
            ? 'text-gray-400 bg-gray-100'
            : 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200'
        }`}
        initial="visible"
        animate={animationMap[hasAnswered]}
        variants={{
          visible: { opacity: 1, x: 0 },
          hidden: { opacity: 0, x: -100 },
          correct: {
            scale: [1, 1.3, 1.3, 1.3, 1, 1],
            x: [0, -5, 5, -5, 0, 0],
            backgroundColor: '#00ff00'
          },
          wrong: {
            scale: [1, 1.3, 1.3, 1.3, 1, 1],
            rotate: [0, 0, 12, 12, -12, 0],
            x: [0, -5, 5, -5, 0, 0],
            opacity: 0.75
          }
        }}
        transition={{ duration: 0.3 }}
        whileTap={{ scale: disabled ? 1 : 0.9 }}
        whileHover={{ scale: 1.1 }}
      ></motion.img>
    </div>
  );
};

export default ImageButton;
