import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

const Error = ({ message, details }: { message: string; details?: string }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <motion.div
        className="text-6xl mb-5"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0, y: +100, zIndex: 0 },
          visible: { opacity: 1, y: 0 }
        }}
        transition={{ duration: 0.8 }}
      >
        😰
      </motion.div>
      <motion.div
        className="bg-red-500 text-white font-bold rounded-lg border shadow-lg p-10"
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0, y: -100, zIndex: 1 },
          visible: { opacity: 1, y: 0 }
        }}
        transition={{ duration: 0.4 }}
      >
        {message}
        {details && (
          <details className="text-red-300 block">
            <summary>Details</summary>
            <div className="w-100">
              <pre>{details}</pre>
            </div>
          </details>
        )}
      </motion.div>
      <Link to="/" className="mt-10 text-blue-400">
        Go back home?
      </Link>
    </div>
  );
};

export default Error;
