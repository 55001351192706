const shuffleArray = (array: any[]) : any[] => {
  if (array.length <= 1) return array;

  const newArray = [...array];
  for (var i = newArray.length - 1; i > 0; i--) {
    // Generate random number
    var j = Math.floor(Math.random() * (i + 1));

    var temp = newArray[i];
    newArray[i] = newArray[j];
    newArray[j] = temp;
  }

  const isSameArray = JSON.stringify(array) === JSON.stringify(newArray);
  if(isSameArray) {
    return shuffleArray(array);
  }

  return newArray;
};

export default shuffleArray;
