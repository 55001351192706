import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

const LetterButton = ({
  text,
  handleSelection,
  isCorrectChoice,
  makeMistake
}: {
  text: string;
  handleSelection: () => void;
  isCorrectChoice: boolean;
  makeMistake: () => void;
}) => {
  const [answeredWrong, setAnsweredWrong] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnsweredWrong(false);
    }, 500);
  }, [answeredWrong]);

  const handleClick = () => {
    if (isCorrectChoice) {
      console.log('correct', text);
      handleSelection();
    } else {
      console.log('wrong!!!', text);
      makeMistake();
      setAnsweredWrong(true);
    }
  };

  return (
    <motion.button
      onClick={handleClick}
      className={`min-w-min text-center px-8 py-3 border border-transparent text-lg font-medium rounded-md md:p-4 m-4 capitalize ${
        answeredWrong
          ? 'text-white bg-red-400'
          : 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200'
      }`}
      initial="hidden"
      animate={answeredWrong ? 'answeredWrong' : 'visible'}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -100 },
        answeredWrong: {
          scale: [1, 1.3, 1.3, 1.3, 1, 1],
          rotate: [0, 0, 12, 12, -12, 0],
          x: [0, -5, 5, -5, 0, 0]
        }
      }}
      transition={{ duration: 0.3 }}
      whileTap={{ scale: answeredWrong ? 1 : 0.9 }}
      whileHover={{ scale: 1.1 }}
    >
      {text}
    </motion.button>
  );
};

export default LetterButton;
