import { motion } from 'framer-motion';
import React, { useMemo, useState } from 'react';
import { FactType } from '../types';
import WordButton from './WordButton';
import Success from './Success';
import { makeWordBlank } from './utils';
import shuffleArray from '../utils/shuffleArray';

const ChooseWord = ({
  fact,
  allWords,
  handleNextGame,
  makeMistake
}: {
  fact: FactType;
  allWords: string[];
  handleNextGame: () => void;
  makeMistake: () => void;
}) => {
  const [wordSelected, setWordSelected] = useState<string>('');
  if(!fact.miniGames['chooseWord']?.length) {
    const message = "No words found in this Choose Word mini game."
    alert(message);
    throw new Error(message);
  }
  const words = fact.miniGames['chooseWord'];
  const word = useMemo(() => words[Math.floor(Math.random() * words.length)], [
    words
  ]);

  const regex = new RegExp(word, 'ig');

  const selectWord = (w: string) => {
    setWordSelected(w);
  };

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren'
      }
    }
  };

  return (
    <>
      {word !== wordSelected ? (
        <>
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl text-center normal-case">
            {fact.phrase.replaceAll(
              regex,
              makeWordBlank(word, wordSelected.split(''))
            )}
          </h1>
          <div className="flex justify-center items-center">
            <motion.div
              className="grid grid-cols-2 sm:grid-cols-3 gap-4 pt-9 lg:w-1/2"
              variants={list}
            >
              {shuffleArray([word, ...allWords]).map(
                (w: string, index: number) => (
                  <WordButton
                    key={w + index}
                    text={w}
                    isCorrectChoice={w === word}
                    handleSelection={() => selectWord(w)}
                    makeMistake={makeMistake}
                  />
                )
              )}
            </motion.div>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl normal-case text-center mb-9">
            {fact.phrase.replaceAll(
              regex,
              makeWordBlank(word, wordSelected.split(''))
            )}
          </h1>
          <Success handleNextGame={handleNextGame} />
        </>
      )}
    </>
  );
};

export default ChooseWord;

