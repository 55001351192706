import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import Spinner from '../shared/Spinner';

const Success = ({ handleNextGame }: { handleNextGame: () => void }) => {
  const messages = [
    'Wow',
    'Oh, yeah',
    'Amazing',
    'Wonderful',
    'Impressive',
  ];
  const message = messages[Math.floor(Math.random() * messages.length)];

  useEffect(() => {
    setTimeout(() => {
      console.log('[Transition] next game plsss!');
      handleNextGame();
    }, 700);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-center items-center">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0, scale: 0.5, y: -40 },
          visible: { opacity: 1, scale: 1.2, y: 0 }
        }}
        transition={{ duration: 0.3 }}
      >
        <h2 className="text-4xl tracking-tight font-extrabold text-green-400 capitalize mt-9 mb-9">
          {message}! 👍
        </h2>
        <Spinner />
      </motion.div>
    </div>
  );
};

export default Success;
