import { motion } from 'framer-motion';
import React from 'react';

const LeaderBoard = () => {

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: .125
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren"
      }
    }
  };

  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 }
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-4">Leader Board</h2>
      <motion.ol initial="hidden" animate="visible" variants={list} className="w-max m-auto">
        <motion.li className="flex justify-between" variants={item}>
          <p>Sam</p>
          <p className="pl-10">2,200</p>
        </motion.li>
        <motion.li className="flex justify-between" variants={item}>
          <p><strong>Alice</strong></p>
          <p><strong>2,000</strong></p>
        </motion.li>
        <motion.li className="flex justify-between" variants={item}>
          <p>Jess</p>
          <p>1,300</p>
        </motion.li>
        <motion.li className="flex justify-between" variants={item}>
          <p>Tom</p>
          <p>250</p>
        </motion.li>
      </motion.ol>
    </>
  );
};

export default LeaderBoard;
