import jsonschema from 'jsonschema';
import { MiniGameType } from '../types';

export const validateJSON = (
  initData: MiniGameType
): jsonschema.ValidatorResult => {
  // Games, to be embedded on Fact
  var gamesSchema = {
    id: '/Games',
    type: 'object',
    properties: {
      spellWord: { type: 'array', items: { type: 'string' } },
      chooseWord: { type: 'array', items: { type: 'string' } },
      imageMatch: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            img: {type: 'string'},
            isCorrect: {type: 'boolean'},
          }
        }
      }
    },
    required: []
  };

  // Fact, to be embedded on game data
  var factSchema = {
    id: '/Fact',
    type: 'object',
    properties: {
      id: { type: 'string' },
      topic: { type: 'string' },
      phrase: { type: 'string' },
      miniGames: { $ref: '/Games' }
    },
    required: ['id', 'topic', 'phrase']
  };

  // GameData
  var schema = {
    id: '/GameData',
    type: 'object',
    properties: {
      title: { type: 'string' },
      company: { type: 'string' },
      facts: { type: 'array', items: { $ref: '/Fact' } }
    },
    required: ['title', 'company']
  };

  const Validator = jsonschema.Validator;
  var v = new Validator();

  v.addSchema(gamesSchema, '/Games');
  v.addSchema(factSchema, '/Fact');
  const validationResults = v.validate(initData, schema);
  console.log({ validationResults });
  return validationResults;
};
