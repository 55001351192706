import { motion } from 'framer-motion';
import React, { useMemo, useState } from 'react';
import { FactType, ImageType } from '../types';
import ImageButton from './ImageButton';
import Success from './Success';
import shuffleArray from '../utils/shuffleArray';

const ImageMatch = ({
  fact,
  handleNextGame,
  makeMistake
}: {
  fact: FactType;
  handleNextGame: () => void;
  makeMistake: () => void;
}) => {
  if(!fact.miniGames['imageMatch']?.length) {
    const message = "No images found in this Image Match mini game."
    alert(message);
    throw new Error(message);
  }

  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const images = fact.miniGames['imageMatch'];
  const shuffledImages = useMemo(() => shuffleArray(images), [
    images
  ]);
  const numCorrectImages = images?.filter((a: ImageType) => a.isCorrect === true).length;
  const selectImage = () => {
    setCorrectAnswers(correctAnswers + 1);
  };

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren'
      }
    }
  };

  return (
    <>
      {numCorrectImages !== correctAnswers ? (
        <>
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl text-center normal-case">
            {fact.phrase}
          </h1>
            <motion.div
              className={`grid grid-cols-2 md:grid-cols-4 gap-5 md:gap-10 mt-10 md:mt-20`}
              variants={list}
            >
              {shuffledImages.map((w: ImageType, index: number) => (
                <ImageButton
                  key={w.img + index}
                  text={w.img}
                  isCorrectChoice={w.isCorrect === true}
                  handleSelection={() => selectImage()}
                  makeMistake={makeMistake}
                />
              ))}
            </motion.div>
        </>
      ) : (
        <>
          <Success handleNextGame={handleNextGame} />
        </>
      )}
    </>
  );
};

export default ImageMatch;
