import React, { useMemo, useState } from 'react';
import { FactType } from '../types';
import { motion } from 'framer-motion';
import Success from './Success';
import shuffleArray from '../utils/shuffleArray';
import { makeWordBlank } from './utils';
import LetterButton from './LetterButton';

const SpellWord = ({
  fact,
  handleNextGame,
  makeMistake
}: {
  fact: FactType;
  handleNextGame: () => void;
  makeMistake: () => void;
}) => {
  const [lettersSelected, setLettersSelected] = useState<string[]>([]);

  if(!fact.miniGames['spellWord']?.length) {
    const message = "No words found in this Spell Word mini game."
    alert(message);
    throw new Error(message);
  }

  const words = fact.miniGames['spellWord'];
  const word = useMemo(() => words[Math.floor(Math.random() * words.length)], [
    words
  ]);

  const letterOptions = useMemo(() => shuffleArray(word.split('')), [word]);

  const regex = new RegExp(word, 'ig');

  const selectLetter = (letter: string) => {
    // is letter the next correct letter?
    if (letter === word[lettersSelected.length]) {
      // add letter to selection
      setLettersSelected([...lettersSelected, letter]);
    } else {
      console.log('wrong!!!');
    }
  };

  const list = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        when: 'afterChildren'
      }
    }
  };

  return (
    <>
      {word.length !== lettersSelected.length ? (
        <>
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl normal-case text-center">
            {fact.phrase.replaceAll(
              regex,
              makeWordBlank(word, lettersSelected)
            )}
          </h1>
          <div className="flex justify-center items-center">
            <motion.div
              className="grid grid-cols-4 sm:grid-cols-5 gap-4 pt-9 lg:w-1/2"
              variants={list}
            >
              {letterOptions.map((l: string, index: number) => (
                <LetterButton
                  key={l + index}
                  text={l}
                  isCorrectChoice={l === word[lettersSelected.length]}
                  handleSelection={() => selectLetter(l)}
                  makeMistake={makeMistake}
                />
              ))}
            </motion.div>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl normal-case text-center mb-9">
            {fact.phrase.replace(regex, makeWordBlank(word, lettersSelected))}
          </h1>
          <Success handleNextGame={handleNextGame} />
        </>
      )}
    </>
  );
};

export default SpellWord;
