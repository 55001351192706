import React, { FC, useState } from 'react';
import { PhaseType } from './types';
import { useQuery, QueryCache, ReactQueryCacheProvider } from 'react-query';
import Game from '../Game';
import { Link, useParams } from 'react-router-dom';
import Error from '../shared/Error';
import Loading from '../shared/Loading';
import { motion } from 'framer-motion';
import logo from '../images/sh-icon.png';
import { FactType } from '../types';
import { validateJSON } from './utils';
import { GameController } from '../api/GameController/GameController';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const Wrapper: FC = ({ children }) => (
  <div>
    <Link
      to="/"
      className="flex items-center p-4 tracking-tight font-extrabold "
    >
      <img src={logo} alt="Sodium Halogen logo" style={{ width: '40px' }} />{' '}
      <span className="pl-2">Mini Game</span>
    </Link>
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
      <div className="sm:text-center lg:text-left">{children}</div>
    </main>
  </div>
);

function Home() {
  const { jsonFileName } = useParams<{ jsonFileName: any }>();
  const [phase, setPhase] = useState<PhaseType>('INTRO');
  const { isLoading, error, data } = useQuery<any>('gameJson', () =>
    fetch(
      `https://sh-mini-game.s3.us-east-1.amazonaws.com/${jsonFileName}.json`
    ).then(res => res.json())
  );

  if (isLoading) return <Loading />;

  if (error)
    return (
      <Error
        message={`An error has occurred fetching "${jsonFileName}" game data`}
      />
    );

  const validationResults = validateJSON(data);
  if (validationResults.errors.length)
    return (
      <Error
        message={`An error has occurred validating "${jsonFileName}" game data`}
        details={JSON.stringify(validationResults.errors, null, 2)}
      />
    );

  if (phase === 'INTRO') {
    return (
      <Wrapper>
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          {data.title}
        </h1>

        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          {data.company}
        </p>

        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          A game of repetition and memorization to ensure you-know-you-know the
          important parts.
        </p>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <button
              onClick={() => setPhase('LEARN')}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
            >
              Start Learning 📚
            </button>
          </div>
        </div>
      </Wrapper>
    );
  }
  if (phase === 'LEARN') {
    return (
      <Wrapper>
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl mb-9">
          First, review the facts.
        </h1>
        <ol className="text-left sm:w-100 md:w-1/2 lg:w-100 m-auto list-decimal text-2xl mx-6">
          {data.facts.map((fact: FactType) => (
            <li key={fact.id} className=" mb-4">
              {fact.phrase}
            </li>
          ))}
        </ol>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <button
              onClick={() => setPhase('GAME')}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
            >
              Start Playing 👉
            </button>
          </div>
        </div>
      </Wrapper>
    );
  } else if (phase === 'GAME') {
    return (
      <Wrapper>
        <Game gameController={new GameController(data.facts)} />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <motion.button
          onClick={() => setPhase('INTRO')}
          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, scale: 0.5, y: -40 },
            visible: { opacity: 1, scale: 1.8, y: 0 }
          }}
          transition={{ duration: 0.3 }}
        >
          Start Over
        </motion.button>
      </Wrapper>
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <ReactQueryCacheProvider queryCache={queryCache}>
    <Home />
  </ReactQueryCacheProvider>
);
