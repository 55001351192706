import { motion } from 'framer-motion';
import React from 'react';

const containerStyle = {
  width: '3rem',
  height: '3rem'
};

const circleStyle = {
  display: 'block',
  width: '3rem',
  height: '3rem',
  border: '0.5rem solid #e9e9e9',
  borderTop: '0.5rem solid #2563EB',
  borderRadius: '50%',
  top: 0,
  left: 0
};

const spinTransition = {
  loop: Infinity,
  ease: 'linear',
  duration: 0.5
};

const Spinner = () => {
  return (
    <div className="flex flex-col items-center justify-center opacity-40">
      <div style={containerStyle}>
        <motion.span
          style={circleStyle}
          animate={{ rotate: 360 }}
          transition={spinTransition}
        />
      </div>
    </div>
  );
};

export default Spinner;
