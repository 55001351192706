import { FactType, PhaseType, MiniGameType } from '../../types';
import shuffleArray from '../../utils/shuffleArray';
import { PHASES } from './PHASES';

export class GameController {
  facts: FactType[];
  phase: PhaseType;
  currentFactIndex: number;
  currentMiniGameType: MiniGameType;
  mistakeCount: number;
  gameTime: number;

  constructor(facts: FactType[]) {
    if (!facts?.length) throw new Error('No facts in game data');

    this.facts = shuffleArray(facts) as FactType[];
    this.phase = PHASES.START;
    this.currentFactIndex = 0;
    this.currentMiniGameType = setMiniGameType(this.facts[0]) as MiniGameType;
    this.mistakeCount = 0;
    this.gameTime = new Date().getTime();
  }

  getCurrentFact() {
    return this.facts[this.currentFactIndex];
  }

  setNextFact() {
    this.currentFactIndex += 1;

    const currentFact = this.facts[this.currentFactIndex];

    this.currentMiniGameType = setMiniGameType(currentFact);
  }

  answerFact(answer?: unknown) {
    if (this.phase === PHASES.END) {
      throw new Error("Can't answer more facts than available.");
    }

    const isCorrectAnswer = answer !== undefined;
    if (!isCorrectAnswer) {
      return false;
    }

    const noMoreFacts = this.currentFactIndex + 1 === this.facts.length;
    if (noMoreFacts) {
      this.phase = PHASES.END;
    } else {
      this.setNextFact();
    }
    return true;
  }

  makeMistake() {
    this.mistakeCount++;
  }

  getSurpassedTime() {
    const timeNow = new Date().getTime();
    const millSecs = timeNow - this.gameTime;
    return millSecs / 1000
  }

  playAgain() {
    this.currentFactIndex = 0;
    this.phase = PHASES.START;
    this.mistakeCount = 0;
    this.gameTime = new Date().getTime();

    const currentFact = this.facts[this.currentFactIndex]
    this.currentMiniGameType = setMiniGameType(currentFact);
  }
}

export function setMiniGameType(currentFact: FactType) {
  const gameTypes = Object.keys(currentFact.miniGames);

  return gameTypes[Math.floor(Math.random() * gameTypes.length)] as MiniGameType;
}