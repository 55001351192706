import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Home";
import Welcome from "./Welcome";

function App() {
  return (
    <Router>
      <>
        <Switch>
          <Route path="/:jsonFileName">
            <Home />
          </Route>
          <Route path="/">
            <Welcome />
          </Route>
        </Switch>
      </>
    </Router>
  );
}

export default App;
